import { combineReducers } from "redux";
import user from "./user";
import invitedUser from "./invitedUser";
import sidebarComponent from "./manageSidebar";
import verifiedEmailComponent from "./verifyEmail";

export default combineReducers({
  user,
  invitedUser,
  sidebar: sidebarComponent,
  verified: verifiedEmailComponent,
});
