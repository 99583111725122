import React, {
  Suspense,
  lazy,
  useEffect,
  useRef,
  useLayoutEffect,
} from "react";
import { Router, Route } from "react-router-dom";
import { Redirect, Switch } from "react-router";
import { connect, useDispatch } from "react-redux";
import { Toaster } from "react-hot-toast";
import lazyRetry from "./utility/lazyRetry";
import { history } from "./managers/history";
import LoadingScreen from "./common/components/loadingScreen";
import TenantService from "./services/userService";
import PrivateLinkService from "./services/privateLinkService";
import DeActivatedScreen from "./common/deActivatedScreen";
import PaymentService from "./services/paymentService";
import { ContentService } from "./services";

const Login = lazy(() => lazyRetry(() => import("./modules/login")));
const SignUp = lazy(() => lazyRetry(() => import("./modules/signUp")));
const WaitList = lazy(() => lazyRetry(() => import("./modules/waitlist")));
const Guide = lazy(() => lazyRetry(() => import("./modules/guide")));
const KnowledgeBaseHome = lazy(() =>
  lazyRetry(() => import("./modules/knowledgeBase"))
);
const ManageApps = lazy(() => lazyRetry(() => import("./modules/manageApps")));
const PublicChatBot = lazy(() =>
  lazyRetry(() => import("./modules/publicChatbot"))
);
const ChatWidget = lazy(() => lazyRetry(() => import("./modules/chatWidget")));
const SignUpVerify = lazy(() =>
  lazyRetry(() => import("./modules/privateInvitation"))
);
const SignInVerify = lazy(() =>
  lazyRetry(() => import("./modules/privateInvitation/login"))
);
const VerifyAccount = lazy(() =>
  lazyRetry(() => import("./modules/privateInvitation/verifyAccount"))
);
const Chat = lazy(() =>
  lazyRetry(() => import("./modules/privateInvitation/chatBot"))
);
const Pricing = lazy(() => lazyRetry(() => import("./modules/pricing")));
const UpgradePlan = lazy(() =>
  lazyRetry(() => import("./modules/pricing/upgradePlan"))
);
const Billing = lazy(() =>
  lazyRetry(() => import("./modules/pricing/billingHistory"))
);
const Usage = lazy(() =>
  lazyRetry(() => import("./modules/pricing/usageLimit"))
);
const ForgotPassword = lazy(() =>
  lazyRetry(() => import("./modules/forgotPassword"))
);
const Users = lazy(() => lazyRetry(() => import("./modules/manageUsers")));
const TermsOfUse = lazy(() => lazyRetry(() => import("./modules/terms")));
const PrivacyPolicy = lazy(() =>
  lazyRetry(() => import("./modules/privacyPolicy"))
);
const SettingComponent = lazy(() =>
  lazyRetry(() => import("./modules/settings"))
);
const Chatbot = lazy(() => lazyRetry(() => import("./modules/chatbot")));
const Flow = lazy(() => lazyRetry(() => import("./modules/flow")));
const ForgotPasswordComponent = lazy(() =>
  lazyRetry(() => import("./modules/privateInvitation/forgotPassword"))
);
const AllApps = lazy(() =>
  lazyRetry(() => import("./modules/appAppsForTeams"))
);
const ManageUser = lazy(() => lazyRetry(() => import("./modules/manageUsers")));

const Routes = ({ user = {} }) => {
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (user.isLoggedIn && params.get("token")) {
      dispatch({ type: "LOGOUT_ON_INVITE_LOGIN" });
    }
  }, []);

  useLayoutEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (user.isLoggedIn && params.get("token")) {
      dispatch({ type: "LOGOUT_ON_INVITE_LOGIN" });
    }
  }, []);

  const userTenantId = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user")).tenantId
    : "";

  useEffect(() => {
    (async () => {
      const { adminTenantId = "", tenantId = "", email = "" } = user;
      if (!adminTenantId) return;

      try {
        const tenantService = new TenantService();
        const response = await tenantService.getTenantDetails(adminTenantId);

        const privateLinkService = new PrivateLinkService();
        const getUserData = await privateLinkService.getUserProfile(
          email,
          userTenantId
        );
        const payload = {
          invitedBy: response.invitedBy,
          email_verified: getUserData?.email_verified,
        };

        if (adminTenantId !== tenantId) {
          const currentTenant = response.invitedBy.find(
            (invite) => invite.tenantId === tenantId
          );

          if (currentTenant) {
            payload.role = currentTenant.role;
          }
        }
        dispatch({
          type: "UPDATE_USER",
          payload: payload,
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    })();
    // eslint-disable-next-line
  }, []);

  const intervelRef = useRef(null);

  const isCompleted = (value) => {
    return Object.keys(value).every((key) => key === "app" || value[key]);
  };

  const getAccountCompletion = async (tenantId) => {
    try {
      const response = await new ContentService().getAccountCompletion({
        tenantId,
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    (async () => {
      const params = new URLSearchParams(window.location.search);
      if (!user.isLoggedIn || params.get("token")) return;

      dispatch({ type: "UPDATE_ACC_COMPLETION", payload: { isLoading: true } });
      const progress = await getAccountCompletion(user?.tenantId);

      dispatch({
        type: "UPDATE_ACC_COMPLETION",
        payload: { ...progress, isLoading: false },
      });
      if (isCompleted(progress)) return;

      intervelRef.current = setInterval(async () => {
        const data = await getAccountCompletion(user?.tenantId);
        dispatch({
          type: "UPDATE_ACC_COMPLETION",
          payload: { ...data, isLoading: false },
        });
        if (isCompleted(data)) clearInterval(intervelRef.current);
      }, 10000);
    })();

    return () => {
      if (intervelRef.current) clearInterval(intervelRef.current);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.isLoggedIn]);

  return (
    <Router history={history}>
      <Suspense fallback={<LoadingScreen />}>
        <Switch>
          <Route exact path={"/"} component={Login} />
          <Route
            exact
            path={"/6918a9182798b11j-all-apps"}
            component={AllApps}
          />
          <Route path="/apps" component={ManageApps} />
          <Route path={"/knowledge-base"} component={KnowledgeBaseHome} />
          <Route exact path={"/signup"} component={SignUp} />
          <Route exact path={"/signup-waitlist"} component={WaitList} />
          <Route exact path={"/forgot"} component={ForgotPassword} />
          <Route exact path={"/guide"} component={Guide} />
          <Route path={"/flow"} component={Flow} />
          <Route path={"/chat/:appId"} component={PublicChatBot} />
          <Route path={"/widget/:appId"} component={ChatWidget} />
          <Route exact path={"/user/sign-up"} component={SignUpVerify} />
          <Route exact path={"/user/sign-in"} component={SignInVerify} />
          <Route exact path={"/user/verify"} component={VerifyAccount} />
          <Route exact path={"/manage-user"} component={ManageUser} />
          <Route
            exact
            path={"/user/forgot-password"}
            component={ForgotPasswordComponent}
          />
          <Route exact path={"/terms"} component={TermsOfUse} />
          <Route exact path={"/privacy"} component={PrivacyPolicy} />
          <Route
            exact
            path={"/app-not-found"}
            render={() => <DeActivatedScreen margin="mt-40" />}
          />

          <Route exact path={"/pricing/overview"} component={Pricing} />
          <Route
            exact
            path={"/pricing/overview/upgradePlan"}
            component={UpgradePlan}
          />
          <Route exact path={"/pricing/history"} component={Billing} />
          <Route exact path={"/pricing/usage-limit"} component={Usage} />
          <Route exact path={"/users"} component={Users} />
          <Route exact path={"/chatbot"} component={Chatbot} />
          <Route path={"/user"} component={Chat} />
          <Route path={"/settings/profile"} component={SettingComponent} />
          <Redirect exact from="*" to="/" />
        </Switch>
      </Suspense>
      <Toaster
        toastOptions={{
          className: "alert-toast font-PoppinsMedium",
          style: {
            borderRadius: "16px",
          },
        }}
      />
    </Router>
  );
};

const mapStateToProps = (state) => {
  return { user: state.user };
};
export default connect(mapStateToProps)(Routes);
